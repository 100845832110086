import React, { useMemo, useState, useEffect } from "react";

// Basic size: S x1, M x2, L x3, XL x4
const config = {
  nX: 32,
  nY: 56,
};

export type Example = {
  // size 0 = S, 1 = M, 2 = L; add 1 then multiply by the config size to get overall
  size: number;
  layers: {
    shapes: {
      points: {
        x: number;
        y: number;
      }[];
      colourIdx: number;
    }[];
    colours: string[];
    opacity: number;
    // NB Scale is not relevant for drawing (as already pre-scaled, only for UI stuff)
    scale: number;
  }[];
};

type Shape = {
  points: string;
  colour: string;
  opacity: number;
};

/**
 *
 * Convert an example into easy to render array of shapes
 *
 * Should take care of scaling etc
 *
 * Memoise as seems egregious to recalculate
 *
 * @param example An example exported from Isometrically
 */
function useExample(example: Example): Shape[] {
  return useMemo(() => {
    let sX = Math.sqrt(3);
    let sY = 1;

    let dX = 200 / (sX * (example.size + 1) * config.nX);
    let dY = 200 / (sY * (example.size + 1) * config.nY);

    const scale = Math.min(dX, dY);

    let shapes: Shape[] = example.layers
      .slice()
      .reverse()
      .flatMap((layer) => {
        return layer.shapes.map((eShape) => {
          return {
            points: eShape.points
              .map(({ x, y }) => `${x * Math.sqrt(3) * scale}, ${y * scale}`)
              .join(" "),
            opacity: layer.opacity,
            colour: layer.colours[eShape.colourIdx],
          };
        });
      });

    return shapes;
  }, [example]);
}

export default function Player({
  example,
  defaultPlaying = false,
  timePerFrame = 100,
  controls,
}: {
  example: Example;
  defaultPlaying?: boolean;
  timePerFrame?: number;
  controls?: (currentlyPlaying: boolean, toggle: () => void) => React.ReactNode;
}) {
  const shapes = useExample(example);
  const N = shapes.length;

  const [isPlaying, setPlaying] = useState(defaultPlaying);
  const [frame, setFrame] = useState(N - 1);

  useEffect(() => {
    if (isPlaying) {
      setFrame(0);

      const iv = setInterval(() => {
        setFrame((f) => {
          if (f + 1 < N) {
            return f + 1;
          } else {
            return 0;
          }
        });
      }, timePerFrame);

      return () => {
        clearInterval(iv);
      };
    } else {
      setFrame(N - 1);
      return () => {};
    }
  }, [isPlaying, timePerFrame]);
  return (
    <>
      <svg viewBox="-100 -100 200 200" onClick={() => setPlaying((s) => !s)}>
        {shapes.slice(0, frame + 1).map((sh, i) => (
          <polygon
            points={sh.points}
            fill={sh.colour}
            opacity={sh.opacity}
            key={i}
          />
        ))}
      </svg>
      {controls ? controls(isPlaying, () => setPlaying((s) => !s)) : null}
    </>
  );
}
