import React from 'react'
import StoreLinks from '../components/storeLinks'
import Grid from "../img/Grid.svg"
import AnimatedIsometrically from "./AnimatedIsometrically";
import InstructionsDownload from "./InstructionsDownload";

export default () => <div className="container">
  <p>Isometrically lets you create Isometric 3D-ish art quickly. For iPhone and iPad.</p>

  <p><em>James Porter</em></p>

  <AnimatedIsometrically />

  <p><em>Isometrically exports SVG images. These can be printed at arbitrary resolutions or like the above image made interactive. Click on it to see a simple transition.</em></p>

  <InstructionsDownload />

  <img src={Grid} />

  <h2>Code Sculpture</h2>

  <p>You might also want to check out Code Sculpture. It allows you to create infinitely complex 3D shapes from simple rules.</p>

  <StoreLinks iOSStoreLink="https://itunes.apple.com/us/app/code-sculpture/id1286544769" />
</div>
