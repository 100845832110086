import React from "react";

import buildings from "../img/Buildings.svg"
import flowers from "../img/Flowers.svg"
import frame from "../img/frame.svg"
import mac from "../img/Mac.svg"
import plane from "../img/Plane.svg"
import wave from "../img/Wave.svg"

export default () => <div className="image-container">
  <img src={frame} />
  <img src={plane} />
  <img src={buildings} />
  <img src={flowers} />
  <img src={mac} />
  <img src={wave} />
</div>;