import React, { useState } from "react"

import Guide from "../components/guide"
import Layout from "../components/layout"
import Player from "../components/Player"
import Previews from "../components/Previews"

// @ts-ignore
import examples from "../data/examples.json"

export default function IndexPage() {
  const [idx, setIdx] = useState(0)

  return (
    <Layout>
      <Player
        example={examples[idx]}
        defaultPlaying={true}
        key={idx}
        timePerFrame={200}
        controls={(isPlaying, toggle) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <button onClick={toggle}>{isPlaying ? "Stop" : "Play"}</button>

            <button
              onClick={() => {
                setIdx((s) => (s > 0 ? s - 1 : examples.length - 1))
              }}
            >
              Previous
            </button>

            <button
              onClick={() => {
                setIdx((s) => (s + 1) % examples.length)
              }}
            >
              Next
            </button>
          </div>
        )}
      />

      <Previews />
      <Guide />
    </Layout>
  )
}
